.navbar {
  background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
 
  height:70px;
  display: flex;
  justify-content: center;

  font-size: 1.1rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9%;
}
.navbar-container .navbar-logo {
  width: 50%;
  margin-left: 35%;
  margin-top: 1%;
}
.link-logo{
  width: 25%;
}
.navbar-logo {
  color: rgb(248, 248, 248);
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  margin-right: 1%;
  margin-top: 1%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 1%;
  
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: left;

}
.nav-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin:0;
}

.nav-links {
  color: rgb(248, 248, 248);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 50%;
}

.nav-links:hover {
  border-left: 2px solid rgb(94, 182, 5);
color:rgb(94, 182, 5);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: rgb(248, 248, 248);
}

.nav-links-mobile {
  display: none;
}
.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }.navbar{
    background: linear-gradient(90deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    height: 10vh;
  }
  .btn-mobile-help{
    display:none;
  } 
  .navbar-container .navbar-logo {
    position: fixed;
    width: 30%;
    margin-left: 4%;
    margin-top:-1%;
  }
  .nav-item{
    height: 10vh;;
  }
  .nav-menu {
    margin-left: 0%;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80%;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background:rgb(0, 0, 0);
  }

  .nav-menu.active {
    background: rgb(0, 0, 0);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    height: 60vh;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: rgb(0, 0, 0);
    color: rgb(94, 182, 5);;
    border-radius: 0;
  }

  .navbar-logo {  
    margin-left: 6%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 50%);
  
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color:rgb(248, 248, 248);
    font-size: 2rem;
  }

  .nav-links-mobile {
   
    display: table;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    text-decoration: none;
    font-size: 1rem;
    background-color: rgb(0, 0, 0);
    color: rgb(94, 182, 5);
    padding: 14px 20px;
    transition: all 0.3s ease-out;
    height: 1vh;
   
  }

  .nav-links-mobile:hover {
    background:grey;
    color: rgb(94, 182, 5);
    transition: 250ms;
  }
}

@media screen and (max-width: 760px) {
  .navbar-container .navbar-logo {
    margin-left: 5%;
    margin-top: 5%;
    
    
  }
}


