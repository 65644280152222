.register-success-div{
    border-radius: 15px;
    border: 2px solid rgb(182, 182, 182);
    display: block;  margin:2%;
    margin:0 auto;
    margin-top: 8%;
    margin-bottom: 8.5%;
    padding: 3%;
  font-size: 19px;
    background-color: rgb(255, 255, 255);;

    width: 35%;
}

@media screen and (max-width: 960px) { 
    .register-success-div{
        
        width: 60%;
    }
}

    @media screen and (max-width: 768px) { 
        .register-success-div{
            font-size: 13px;
            width: 90%;
        }
    }