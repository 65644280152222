html {
  scroll-behavior: smooth;
}

.hero-container {
  scroll-behavior: smooth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }
  .container > h2 {
    color: #fff;
    margin-top: 10%;
    margin-left: 15%;
    font-size: 32px;;
  }
  .hero-container .container {
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    height: 100%;
  }
  .container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 28px;
    margin-left: 15%;
    
  }
  
  .hero-btns {
    margin-top: 32px;
    margin-left: 15%;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  a { 
    color: #fff;; 
    text-decoration: none; 
  
  }

  .arrow {
    text-align: center;
    margin: 15% 0;
  }
  .bounce {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .container > h2  {
      font-size: 25px;
      margin-left: 6%;
      margin-top: 13%;
      word-wrap: break-word;
    }

    .container > p  {
      font-size: 20px;
      margin-left: 6%;
      margin-top: 1%;
      width: 50%;
    }

    .arrow {
      margin: 1% 0;
    }
    .hero-btns {
      margin-left: 6%;
      position: relative;
      top: -3%;
    }
  }
  
  @media screen and (max-width: 760px) {
    
    .hero-container{
      overflow: hidden;
      max-width: 115vh;
    }
    .hero-container> img{
      width: 115vh;
    }

    .container > h2  {
      font-size: 20px;
      margin-top: 27%;
      word-wrap: break-word;
    }
    .container > p  {
      font-size: 18px;
      margin-top: 2%;
      word-wrap: break-word;
      width: 70%;
    }
    .arrow {
      margin: 11% 0;
    }
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .hero-btns {
      position: relative;
      top: -1%;
    }
  }