.login-div{
    border-radius: 15px;
    border: 2px solid rgb(182, 182, 182);
    display: block;  margin:2%;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 3%;
  
    background-color: rgb(255, 255, 255);;

    width: 30%;
}  
.inside-eye {
    position: relative;
}
.p-viewer {
	z-index: 9999;
	position: absolute;
	top: 50%;
	right: 10px;
}
i{
    right: 12%;
    top:55%;
}
  
.link{
    text-decoration: none;  
    color:green;
}
.link:hover{
    color:rgb(15, 75, 15);
}

.login-button{
    border-radius: 5px;
     border: 2px solid rgb(95, 148, 95);
     background-color: rgb(147, 228, 147);
}
.login-button:hover{
    background-color: rgb(84, 143, 84);
}

.input:focus{
    outline-color: rgb(84, 143, 84);
}

.field-icon {
    float: right;
    position: relative;
}

.input{
    width: 100%;
}
.login-div label{
    width:100%;
}
  @media screen and (max-width: 960px) { 
    .login-div{
       width: 60%;
       font-size: 16px;
    }
}

    @media screen and (max-width: 768px) { 
        .login-div{
            font-size: 13px;
            width: 90%;
        }
        i{
            right: 5%;
            top:58%;
        }
    }
  