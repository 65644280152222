:root {
    --primary: rgb(248, 248, 248);
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  .btn-help {
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: transparent;
    color: rgb(94, 182, 5);
    border: 1px solid white;
  }
  .btn--primary-help {
    background-color: transparent;
    color: rgb(94, 182, 5);
  }
  
  .btn--outline {
    background-color: transparent;
    color: rgb(94, 182, 5);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  .btn--outline-help {
    background-color: transparent;
    color: rgb(94, 182, 5);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }

  .btn--www{
    background-color: transparent;
    color: red;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
    color:rgb(94, 182, 5);
   
  }
  .btn--medium-help {
    padding: 8px 20px;
    font-size: 18px;
    color:rgb(94, 182, 5);
   
  }
  
  .btn--large {
    padding: 10px 20px;
    font-size: 20px;
    color:rgb(94, 182, 5);
    font-weight: 700; 
  }
  .btn--large-help {
    padding: 10px 20px;
    font-size: 20px;
    color:rgb(94, 182, 5);
    font-weight: 700; 
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: rgb(77, 77, 77);
    color: #b1b1b1;
    transition: 250ms;
  }

  .btn--large-help:hover,
  .btn--medium-help:hover {
    transition: all 0.3s ease-out;
    background: rgb(77, 77, 77);
    color: #b1b1b1;
    transition: 250ms;
  }