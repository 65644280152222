.container-footer{
    display: inline-block;
    background-color: black;
    color: rgb(94, 182, 5);
    height: 100%;
    text-align: center;
    width: 100%;
}

.div-footer{
    margin-top: 2%;
     display:inline-block;
    width: 15%;
    vertical-align: top;
    word-wrap: break-word;
}
.footer-logo{
    
    margin-top: 10%;
    vertical-align: top;
}
.div-text{
    display:inline-block;
}
.div-text>h1{
        font-size: 20px;
        text-align: center;
        padding: 1%;
}
.div-text> h3{
    font-size: 15px;
    padding: 2%;

}
.div-copy{
    margin-bottom: 2%;
    margin-top: 2%;
    text-align: right;
}
.footer-hr{

    width: 70%;
    margin: auto ;
    margin-top: -2%;
}
.menu-footer ul{
    width: 70%;
    margin: auto ;
    font-size: 13px;;
  
}
.menu-footer li{
    margin-left: 1%;
    padding: 1%;
  
}
.menu-footer ul li{
   float:left;
}
 .menu-footer ul li a:active,
 .menu-footer ul li a:hover {
    color:rgb(94, 182, 5);
 }
 @media screen and (max-width: 960px) {
    .footer-hr{
        margin-top: 5%;
    }
     .div-footer{
        margin-bottom: -5%;
         width: 25%;
     }
     .div-text>h1{
         font-size: 15px;
     }
     .div-text>h3{
         font-size: 11px;
     }
     .menu-footer li{
         width: 100%;
     }.div-copy{
         text-align: left;
     }
     .menu-footer ul{
         font-size: 11px;
     }
     .footer-logo{
     width: 80%;
     }
   }
 
   @media screen and (max-width: 760px) {
     .div-footer{
         width: 90%;
         margin-bottom: 0;
     }
     .footer-logo{
        margin-top: 2%;
        margin-bottom: 2%;
         width: 45%;
     }
     .menu-footer li{
         width: 100%;
     }
     .div-text>h1{
         font-size: 13px;
     }
     .div-text>h3{
         font-size: 11px;
     }
    
   }
 
