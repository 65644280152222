
.forgot-div{
    border-radius: 15px;
    border: 2px solid rgb(182, 182, 182);
    display: block;  margin:2%;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 3%;
  
    background-color: rgb(255, 255, 255);;
   
    width: 30%;
}
.forgot-div label{
    width:100%;
}
.input{
    width: 100%;
}
.link{
    text-decoration: none;  
    color:green;
}
.link:hover{
    color:rgb(15, 75, 15);
}

.login-button{
    border-radius: 5px;
     border: 2px solid rgb(95, 148, 95);
     background-color: rgb(147, 228, 147);
}
.login-button:hover{
    background-color: rgb(84, 143, 84);
}

.input:focus{
    outline-color: rgb(84, 143, 84);
}
  @media screen and (max-width: 960px) { 
    .forgot-div{
        width: 40%;
    }
}

    @media screen and (max-width: 768px) { 
        .forgot-div{
            font-size: 13px;
            width: 90%;
        }
    }