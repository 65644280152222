.obsluga-img{
    display: inline-block;
    margin:5%;
    margin-left: 20%;
    margin-top: 7%;
}
.obsluga-text{
    display: inline-block;
    vertical-align: top;
   margin:2%;
   margin-left: -10%;
   margin-top: 5%;
    padding: 3%;
    color: white;
    width: 40%;
}
.container-budowa{  
    color: white;
    margin: 0 auto;
 
}
.obsluga-kafle{
    margin-left: 18%;
    margin-top: 7%;
    margin-bottom: 2%;
  }
  .kafelIT{
    color:black;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    margin: 1%;
    padding: 1%;
    text-align: center;
  }
  .kafelIT>h3{
font-size: 13px;
  }
  .obsluga-kafle-img{
    width: 30%;
  }

  .obsluga-opis-otrzymasz-container{
    display: inline-block;
    color:black;
    background-color: rgb(240, 240, 240);
     -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    border-radius: 15px;
    width: 60%;
    margin-left: 20%;
    padding: 3%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.obsluga-opis-otrzymasz-container:hover{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}
.obsluga-opis-otrzymasz-container-text{
    color:rgb(184, 184, 184);
    background-color:pink;
    text-align: center;
    margin:5%;
       vertical-align: top;
    display: inline-block;
    word-wrap: break-word;
    width: 80%;
    justify-Content: center;
}
.obsluga-dlaczego{
    background-color: rgb(102, 102, 102);
    margin:0 auto;    margin-top: 2%;

    width: 100%;
}
.dlaczego-text{
    font-size: 20px;
    padding: 2%;
    margin-left: 10%;
    width: 80%;
    text-align: center;
    word-wrap: break-word;
}
@media screen and (max-width: 960px) {
  .obsluga-text{
    width: 60%;  margin-left: 35%;
    margin-top: -35%;
}
.obsluga-text>h1{
 font-size: 28px;
}
.obsluga-text>h2{
 font-size: 18px;
}
.obsluga-text>h3{
 font-size: 13px;
}
.obsluga-img{
 margin-top: 12%;
 margin-left: 5%;
 width: 40%;
}
.kafelIT{
  width: 30%;
}
.obsluga-kafle{
  margin-left: 1%;
}
.obsluga-opis-otrzymasz-container{
  width: 80%;
  margin-left: 10%;
  
}
.obsluga-opis-otrzymasz-container>h1{
font-size: 25px;
}
}
@media screen and (max-width: 760px) {
  .obsluga-text{
    width: 60%;  margin-left: 35%;
    margin-top: -35%;
}
.obsluga-text>h1{
 font-size: 25px;
}
.obsluga-text>h2{
 font-size: 15px;
}
.obsluga-text>h3{
 font-size: 13px;
}
.obsluga-img{
 margin-top: 20%;
 margin-left: 5%;
 width: 40%;
}
.kafelIT{
  width: 90%;
  margin-left: 5%;
}
.kafelIT>img{
  width: 15%;
}
.kafelIT>h2{
  font-size: 18px;
   }
   .kafelIT>h3{
    font-size: 12px;
     }

     .dlaczego-text{
      font-size: 15px;
     }
     .obsluga-opis-otrzymasz-container>h1{
font-size: 20px;
     }
     .obsluga-opis-otrzymasz-container>h3{
      font-size: 15px;
    }
}

@media screen and (max-width: 440px) {
  .obsluga-text{  margin-left: 35%;
    margin-top: -43%;
  }
  .obsluga-text>h1{
   font-size: 25px;
  }
  .obsluga-text>h2{
   font-size: 15px;
  }
  .obsluga-text>h3{
   font-size: 13px;
   margin-left: -50%;
  }
  .obsluga-img{
    margin-top: 33%;
  }
}