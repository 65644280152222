.container-vps{  
    scroll-behavior: smooth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;

  
}
.container-vps .image-title{
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    height: 100%;
}
.vps-text{
    display: inline-block;
   vertical-align: top;
   margin:2%;
   margin-left: -10%;
   margin-top: 5%;
    padding: 3%;
    color: white;
    width: 40%;
}
.vps-img{
    display: inline-block;
    margin:5%;
    margin-left: 20%;
    
}

@media screen and (max-width: 960px) {
    .vps-text{
        width: 60%;  margin-left: 35%;
        margin-top: -35%;
    }
    .vps-text>h1{
     font-size: 28px;
    }
    .vps-text>h2{
     font-size: 18px;
    }
    .vps-text>h3{
     font-size: 13px;
    }
    .vps-img{
     margin-top: 12%;
     margin-left: 5%;
     width: 40%;
    }
}


@media screen and (max-width: 760px) {
    .vps-text>h1{
        font-size: 25px;
       }
       .vps-text>h2{
        font-size: 15px;
       }
       .vps-text>h3{
        font-size: 12px;
       }
       .vps-img{
        margin-top: 20%;
        margin-left: 5%;
        width: 40%;
       }
}

@media screen and (max-width: 440px) {
    .vps-text{  margin-left: 35%;
        margin-top: -30%;
    }
    .vps-text>h1{
     font-size: 25px;
    }
    .vps-text>h2{
     font-size: 15px;
    }
    .vps-text>h3{
     font-size: 13px;
     margin-left: -50%;

    }
    .vps-img{
        margin-top: 25%;
      }
}