.hosting-img{
    display: inline-block;
    margin:5%;
    margin-left: 20%;
}
.hosting-text{
    display: inline-block;
    vertical-align: top;
   margin:2%;
   margin-left: -10%;
   margin-top: 5%;
    padding: 3%;
    color: white;
    width: 40%;
}
.container-budowa{  
  scroll-behavior: smooth;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  
}
.container-budowa .image-title{
  position: absolute;
  bottom: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}
.h1-title{
    font-size: 50px;
}

.hosting-table{
    margin-left: -10%;
}
.hosting-table table {
    color:black;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 130%;
    margin-top: 15%;
  }
  td, th {
    border: 1px solid rgb(219, 219, 219);
    text-align: center;
    padding: 8px;
  }
   tr:nth-child(even) {
    border: 1px solid rgb(224, 224, 224);
  }

th:first-child + th { /* second column */ 
    transition: transform .2s;
}

th:first-child + th + th { /* third column */ 
    transition: transform .2s;
}
th:first-child + th + th +th{ /* third column */ 
    transition: transform .2s;
}
  .th-title{
    text-align: left;
  }
  th{
    height: 100px;
    text-align: center;
  }
  .th-price{
    font-size: 30px;
    background-image: linear-gradient(to bottom right, green, rgb(94, 182, 5));
    color:white;
  }

th:first-child + th:hover { /* second column */ 
    transform: scale(1.1);
}

th:first-child + th + th:hover { /* third column */ 
    transform: scale(1.1);
}
th:first-child + th +th+ th:hover { /* third column */ 
    transform: scale(1.1);
}
 
  .hosting-cena{
    color:black;
    width:70%;
    margin-top: 1%;
    margin-left: 15%;
    font-size: 12px;
  }
  .hosting-button{
    background-color: transparent;
    color: rgb(94, 182, 5);
    border: 1px solid rgb(94, 182, 5);
    width: 60%;
    padding: 2%;
    transition: transform .2s;
  }
  .hosting-button:hover{
    background-color: rgb(94, 182, 5);
    color: black;
    transform: scale(1.1);
  }
  .hosting-kafle{
    margin-left: 18%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .kafel{
    color:black;
    display: inline-block;
    width: 25%;
    vertical-align: top;
    margin: 1%;
    padding: 1%;
    text-align: center;
  }
  .kafel>h3{
font-size: 13px;
  }
  .hosting-kafle-img{
    width: 30%;
  }

  @media screen and (max-width: 960px) {
    .hosting-text{
      width: 60%;  margin-left: 35%;
      margin-top: -35%;
  }
  .hosting-text>h1{
   font-size: 28px;
  }
  .hosting-text>h2{
   font-size: 18px;
  }
  .hosting-text>h3{
   font-size: 13px;
  }
  .hosting-main{
      margin-left: 60%;
     
  }
  .hosting-img{
   margin-top: 12%;
   margin-left: 5%;
   width: 40%;
  }
  .hosting-table table {
    width: 90%;
    margin-left: 10%;
  }
  .th-title{
    width: 90%;
  }
  .hosting-cena{
    width: 80%;
    margin-left: 5%;
    font-size: 10px;
  }
 .kafel>h2{
font-size: 18px;
 }
 .kafel>h3{
  font-size: 12px;
   }
 .kafel>img{
  width: 20%;
}
  }

  @media screen and (max-width: 760px) {
    .hosting-text{
      width: 60%;  margin-left: 35%;
      margin-top: -35%;
  }
  .hosting-text>h1{
   font-size: 25px;
  }
  .hosting-text>h2{
   font-size: 15px;
  }
  .hosting-text>h3{
   font-size: 12px;
  }
  .hosting-main{
      margin-left: 60%;
     
  }
  .hosting-img{
   margin-top: 20%;
   margin-left: 5%;
   width: 40%;
  }
  .hosting-table> table {
    margin-left: 5%;
    font-size: 9px;
    width: 100%;
  }
  .th-title{
    width: 90%;
  }
  .hosting-cena{
    width: 80%;
    margin-left: 5%;
    font-size: 10px;
  }
  .kafel{
    width:80%;
 
  }
  .hosting-kafle{
       margin-left: 15%;
  }
 .kafel>img{
  width: 15%;
}
td, th {
  padding: 1%;
}
.th-price{
  font-size: 18px;
  }
  .hosting-button{
    font-size: 9px;
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  .hosting-text{  margin-left: 35%;
    margin-top: -35%;
  }
  .hosting-text>h1{
   font-size: 25px;
  }
  .hosting-text>h2{
   font-size: 15px;
  }
  .hosting-text>h3{
   font-size: 13px;
   margin-left: -50%;
  }
  .hosting-img{
    margin-top: 25%;
  }
}