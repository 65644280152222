.container-help{
    padding: 5%;
    text-align: center;
    
}

.container-border{
    border: 5px solid chartreuse;
    padding: 10%;
}
@media screen and (max-width: 960px) {
    .container-help{
        font-size: 60%;;
    }
}