
.hero-icons-container{
    margin:0 auto;
    margin-left:2%;
}
.icon{
    display:inline-block;
    background-color:rgb(238, 238, 238) ;
   margin:1%;
    margin-left:5%;
    margin-top: 5%;
    
    width:40%;


    /*box-shadow: 30px -30px rgb(57, 57, 57);*/
    vertical-align: top;
    word-wrap: break-word;
    -webkit-box-shadow: 32px -17px 39px -12px rgba(130, 130, 130, 1);
-moz-box-shadow: 32px -17px 39px -12px rgba(130, 130, 130, 1);
box-shadow: 32px -17px 39px -12px rgba(130, 130, 130, 1);
}
.icon:hover{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}
.icon> h1{
    font-size: 20px;
    text-align: center;
    padding: 2%;

}
.icon> h3{
    text-align: center;
    font-size: 13px;
    padding: 2%;

}
.icon >a>button{
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 4px 0px inset;
    width: 30%;
    font-size: 20px;
    font-weight: 600;
    color:black;
    padding: 2%;
    margin:2%;
    margin-left:35%;
    background-color: rgb(190, 190, 190);;
    border: solid 2px #fff;
}
.icon >a >button:hover{
    background-color: rgb(105, 105, 105);
    
}
.icon .img{
    margin-top: 2%;;
}
.icon >hr{
    background-color:chartreuse;
}
.hero-icons{
    scroll-behavior: smooth;
    margin: auto;
    width: 100%;
}
html {
    scroll-behavior: smooth;
  }

  .hero-bottom-white{
    height: 10vh;
  }

  @media screen and (max-width: 960px) {
    .icon{
        width: 45%;
        margin-left: 1%;
    }
    .icon> h1{
        font-size: 18px;
    }
    .icon> h3{
        font-size: 13px;
    }
    .icon >a >button:hover{
        background-color: rgb(190, 190, 190);
        
    }
    .icon >a>button{
        width: 90%;
        font-size: 15px;
        font-weight: 600;
        color:black;
        padding: 2%;
        margin:5%;
    }
  }

  @media screen and (max-width: 760px) {

    .icon{
            width: 87%;
            margin-left: 5%;
     
    }
    .icon> h1{
        font-size: 18px;
    }
    .icon> h3{
        font-size: 13px;
    }
    .icon >a >button:hover{
        background-color: rgb(190, 190, 190);
        
    }
  }
