 * 
{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight:900;
}
html {
  scroll-behavior: smooth;
}
.home,
.web,
.hosting,
.data,
.cennik,
.obslugaIT,
.about,
.rodo,
.kontakt,
.helpAPI,
.pomoc-zdalna {
  display: flex;
  height: 600vh;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}