.dropdown-menu {
    width: 25%;
    position: absolute;
    top: 100%;
    list-style: none;
    text-align: start;
    font-weight: 700;
    margin-left: -5%;
  }
  
  .dropdown-menu li {
    background: rgb(71, 141, 2);
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: rgb(174, 252, 97);
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 20px;
  }
  
  @media screen and (max-width: 960px) {
    .dropdown-menu{
      width: 110%;
      position:absolute;
      top:170px;
      list-style: none;
      text-align: center;
  }
}