.register-div{
    border-radius: 15px;
    border: 2px solid rgb(182, 182, 182);
    display: block;  margin:2%;
    margin:0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 3%;
  
    background-color: rgb(255, 255, 255);;
   
    width: 30%;
}
 .register-div label{
     width:100%;
 }
.inside-eye i {
    color:green;
    position: absolute;
    right: 12%;
    top:40%;
}
.register-div::-webkit-scrollbar {
    display: none;
}
.link{
    text-decoration: none;  
    color:green;
}
.link:hover{
    color:rgb(15, 75, 15);
}

.login-button{
    border-radius: 5px;
     border: 2px solid rgb(95, 148, 95);
     background-color: rgb(147, 228, 147);
}
.login-button:hover{
    background-color: rgb(84, 143, 84);
}
.input:focus{
    outline-color: rgb(84, 143, 84);
}
.select:focus{
    outline-color: rgb(84, 143, 84);
}
.input{
    width: 100%;
}
@media screen and (max-width: 960px) { 
    .register-div{
        width:90%;
    }
    .inside-eye i {
        position: absolute;
        right: 5%;
        top:47%;
    }
}

    @media screen and (max-width: 768px) { 
        .register-div{
            width:90%;
            font-size: 13px;
        }
        .rodo-register{
            font-size: 10px;
        }
        .inside-eye i {
            position: absolute;
            right: 8%;
            top:29%;
        }
        
    }
  