html {
    scroll-behavior: smooth;
  }
  
.opis-budowa-container{
    margin-top: 5%;
 
}
.opis-budowa-mini{
    display:inline-block;
    margin:2%;
    margin-left: 1%;
    width:30%;
    border-radius: 15px;
    font-size: 12px;
    vertical-align: top;
    word-wrap: break-word;
}

.opis-budowa{
    display: inline-block;

margin-left: 20%;
    padding: 3%;
    color: rgb(29, 29, 29);
    width: 60%;
}

.opis-otrzymasz-container{
    display: inline-block;
    color:black;
    background-color: rgb(240, 240, 240);
     -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    border-radius: 15px;
    width: 85%;
    margin-left: 5%;
    padding: 3%;
}

.opis-otrzymasz-container:hover{
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    transition: 1s ease;
}
.opis-otrzymasz-container-text{
    color:rgb(184, 184, 184);
    background-color:pink;
    text-align: center;
    margin:5%;
       vertical-align: top;
    display: inline-block;
    word-wrap: break-word;
    width: 80%;
    justify-Content: center;
}
.title{
    margin-top: 50%;
}
.top-image{
    margin-top: -35%;
}
.container-www{
   margin:2%;
   color:rgb(29, 29, 29);
}
.img-main{
    margin-top: -20%;
    width:50%;
    position: absolute;
}
.image-title-budowa{
    background-color: white;
    position: relative;
    
}
.img-icon{
    display: inline-block;
    margin:5%;
    margin-left: 20%;
    
}
.h1-title{
    font-size: 50px;
}
.img-text{
    display: inline-block;
   vertical-align: top;
   margin:2%;
   margin-left: -10%;
    padding: 3%;
    color: white;
    width: 40%;
}
.container-budowa-kontakt{
   
    background-color: rgb(29, 29, 29);;
    color: white;
    width: 100%;
}
.strony{
    background-color: rgb(102, 102, 102);
    margin:0 auto;    margin-top: 2%;

    width: 100%;
}
.strony-container-1{
    display: inline-block;
    margin: 0 auto;
    padding: 2%;
    margin-left: 10%;  
}
.strony-container-2{
    display: inline-block;
    vertical-align: middle;
   
    padding: 2%;
    font-size: 0.8em;;
    width: 40%;

}

.strony-container-2>img{
        border: 1px solid;
    padding: 10px;
    box-shadow: 100px -36px rgb(57, 57, 57);
}
.budowa{
    margin-left: 1%;
    margin-right: 1%;
    display:inline-block;
    vertical-align: top;
    word-wrap: break-word;
    text-align: center;
  
}
.budowa>h1{
    font-size: 26px;
}
.budowa>h2{
    margin: 2%;
    margin-left: 3%;
    margin-right: 8%;
    font-size: 20px;
}
.budowa >img{
    display: block;
    margin: 0 auto;
    transition: all 250ms linear 5s;
}
.budowa-1{
    margin-top: 30%;
    margin-left: 1%;
    margin-right: 1%;
    display:inline-block;
    border: 5px solid chartreuse;
    vertical-align: top;
    word-wrap: break-word;
}
.budowa-1>h1{
    margin: 2%;
    margin-left: 8%;
    font-size: 26px;
}
.budowa-1>h2{
    margin: 2%;
    margin-left: 8%;
    margin-right: 8%;
    font-size: 20px;
}
.budowa-1>img{
    display: block;
    margin: 0 auto;
    transition: all 250ms linear 5s;
}
.container-budowa{  
    scroll-behavior: smooth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;

  
}
.container-budowa .image-title{
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    height: 100%;
}
.container-text{
    display: block;  
    margin-top:2%;
    margin: 0 auto;
    padding: 1%;
    
}
.container-text>h2{
    display: inline;  
    font-size: 30px;
    margin-left: 15%;
    font-weight: bolder;
}
.container-text>h3{
    margin-left: 15%;
}
.container-text>img{
    margin-left: 50%;
    width:20%;
}
.container-text >a>button{
    background-color: rgb(94, 182, 5);
    color: black;
    font-size: larger;
    padding: 20px;
    width: 100%;
    margin-top: -50%;
    border: 1px solid rgb(94, 182, 5);
    transition: all 0.3s ease-out;
}
.container-text >a >button:hover{
    transition: all 0.3s ease-out;
    background: rgb(77, 77, 77);
    color: rgb(94, 182, 5);
    transition: 250ms;
}

.img-search{
    margin: 0%;
}
.container-text>li{
   display: inline-block;
   
}
ul li{
   list-style: none; 
   margin-left: 15%;
}

.cennik-napis{
    margin:2%;
    margin-left: 8%;
}


.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }

  .strony-container-2 >a>button{
    border-radius: 15px;
    width: 60%;
    font-weight: 600;
    color:black;
    padding: 2%;
    margin-top:5%;
    background-color: #fff;
    border: solid 2px #fff;
}
.strony-container-2 >a >button:hover{
    background-color: rgb(190, 190, 190);
    
}

.column {
    max-width: 35%;
    display: inline-block;
    
  margin:1%;
  }
  .container {
    position: relative;
    display: inline-block;
    margin:1%;
    width: 45%;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgb(79, 153, 5);
  }
  
  .container:hover .overlay {
    opacity: 0.9;
  }
  
  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
.portfolio-img{
    display: inline-block;
    margin-left: 1%;
}


  @media screen and (max-width: 960px) {
    .opis-budowa-mini{
        width:45%;
    }
    .opis-budowa{
        margin-left: 8%;
        width:90%;
    }
    .budowa{
       margin-left: 1%;
       margin-right: 1%;
   } 
   .image-title{
       height: 20%;
   }
   .budowa-1{
       margin-top: 0%;
       margin-left: 1%;
       margin-right: 1%;
   }
   .container-text{
       margin-top:1%;
       margin-bottom: 1%;
       margin-left: 5%;
   }
   .container-text>img{
       margin-left: 75%;
       margin-top: -100%;
   }
   ul li{
       margin-left: 1%;
   }
   
   .container-text>h2{
       font-size: 20px;
       margin-left: 1%;
   }
   .icon-budowa{
       width: 85%;
       margin-left: 8%;
   }
   .img-text{
       width: 60%;  margin-left: 35%;
       margin-top: -40%;
   }
   .img-text>h1{
    font-size: 28px;
   }
   .img-text>h2{
    font-size: 18px;
   }
   .img-text>h3{
    font-size: 13px;
   }
   .img-main{
       margin-left: 60%;
      
   }
   .img-icon{
    margin-top: 12%;
    margin-left: 5%;
    width: 40%;
   }
   .opis-otrzymasz-container{
    width: 90%;
    margin-left: 5%;
   }
   .strony-container-1{
    margin-left: 1%;
    width: 100%;
   }
   .strony-container-2{
    width: 80%;
    margin-left: 10%;
    margin-top: 2%;
   }
   .strony-container-2 >a>button{
    width: 90%;
   }
   .container {
    width: 90%;
   }
   .opis-otrzymasz-container>h1{
    font-size: 25px;
    }
}


@media screen and (max-width: 760px) {
    .container-budowa{
        overflow: hidden;
        max-width: 115vh;
      }
      .container-budowa> img{
        width: 115vh;
      }

    .opis-budowa-mini{
        width:100%;
        margin-left: -3%;
    }
   .budowa{
       margin-left: 1%;
       margin-right: 1%;
   }
   .budowa>h1{
       margin: 2%;
       font-size: 22px;
   }
   .budowa>h2{
       margin: 2%;
       font-size: 18px;
   }

   .budowa >img{
       width: 70%;
   }
   .image-title{
       height: 20%;
   }
   .budowa-1{
       margin-top: 0%;
       margin-left: 1%;
       margin-right: 1%;
   }
   .budowa-1>h1{
       margin: 2%;
       font-size: 22px;
   }
   .budowa-1>h2{
       margin: 2%;
       font-size: 18px;
   }

   .budowa-1 >img{
       width: 70%;
   }
   .container-text{
       margin-top:5%;
       font-size: 18px;
       margin-left: 1%;
       width: 100%;
   }
   .container-text >a>button{
       width: 100%;
       margin-left: 0%;
       padding: 2%;
   }

   .img-text{
       font-size: 10px;
   }
   ul li>img{
       width: 4%;
   }
   .img-main{
       margin-top: -35%;
   }

   .img-text{
    width: 60%;  margin-left: 35%;
    margin-top: -40%;
}
.img-text>h1{
 font-size: 25px;
}
.img-text>h2{
 font-size: 15px;
}
.img-text>h3{
 font-size: 13px;
}
.img-main{
    margin-left: 60%;
   
}
.img-icon{
 margin-top: 20%;
 margin-left: 5%;
 width: 40%;
}
.opis-otrzymasz-container>h1{
   font-size: 20px;
   }
   .opis-otrzymasz-container>h3{
    font-size: 15px;
    }
    .strony-container-1{
        width: 100%;
       }
       .strony-container-2{
        width: 90%;
        margin-left: 1%;
        margin-top: 10%;
       }
       .strony-container-2 >a>button{
        width: 100%;
       }
}

@media screen and (max-width: 440px) {
    .img-text{  margin-left: 35%;
        margin-top: -40%;
    }
    .img-text>h1{
     font-size: 25px;
    }
    .img-text>h2{
     font-size: 15px;
    }
    .img-text>h3{
     font-size: 13px;
     margin-left: -50%;

    }
    .img-icon{
        margin-top: 25%;
      }
}

