.contact-container{  
    scroll-behavior: smooth;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}
.contact-container .image-title{
    position: absolute;
    bottom: 0;
    color: #fff;
    width: 100%;
    height: 100%; margin-top: 10%;
}
.contact-text{
    display: inline-block;
   vertical-align: top;
   margin:2%;
   margin-left: -10%;
    padding: 3%;
    color: white;
    width: 40%;
    margin-top: 5%;
}
.contact-img{
    display: inline-block;
    margin:5%;
    margin-left: 20%;
    margin-top: 10%;
}


.contact-mini-tel{
    display: inline-block;
    width: 35%;
    color: rgb(29, 29, 29);
    text-align: center;
    margin: 2%;
   
    margin-left: 15%;
    padding: 1.5%;
    border-bottom:rgb(94, 182, 5);
    box-shadow:
      inset 60px 0 120px rgb(224, 224, 224), /* left magenta */
      inset -60px 0 120px rgb(78, 78, 78);
      box-shadow: 0 8px 8px -4px  rgb(94, 182, 5);
    /* -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 1); */
}
.contact-mini-mail{
    color: rgb(29, 29, 29);
    display: inline-block;
    width: 35%;
    text-align: center;
    margin: 2%;
    margin-bottom: 5%;
    margin-left: 0;
    padding: 1.5%;
    box-shadow: 0 8px 8px -4px  rgb(94, 182, 5);
}
.text-mini-kafle{ margin:2%;
    margin-top: 15%;
   
    text-align: center;
    color: rgb(29, 29, 29);
}
.contact-form{
    display: block;
    color: rgb(29, 29, 29);
    text-transform: none;
    margin-left: 15%;
    padding: 3%;
    width: 70%;
}
.required:before {
    content:" *";
    color: red;
  }
.app-form-button{
    margin:2%;
    display: inline-block;
margin:0 auto;
display: block;
text-align: center;

}
.app-form-button>button{
    margin-top:1%;
  width: 100%;  
  padding: 2%;
  margin-bottom: 2%;
}





.screen-body-item {
    flex: 1;
    padding: 2%;
  }
  
  .screen-body-item.left {
    display: flex;
    flex-direction: column;
  }
  
  
  .app-contact {
    margin-top: auto;
    font-size: 8px;
    color: #888;
  }
  
  
  .app-form-control {
    width: 100%;
    padding: 2%;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    color: #888;
    font-size: 14px;
    outline: none;
    transition: border-color .2s;
  }
  
  .app-form-control::placeholder {
    color: grey;
  }
  .app-form-control:focus {
    border-bottom-color: white;
  }
  


@media screen and (max-width: 960px) {
    .contact-mini-tel{
        width: 40%;
        margin-left: 10%;
    }
    .contact-mini-mail{
        width: 40%;
    }
    .contact-form{
        width: 82%;
        margin-left: 10%;
    }
    .div-contact-form-name{width: 100%;
    }
    #input-field{
    width: 350%;
    }
    .textarea-msg{
        width: 350%;
    }
    .div-contact-form-phone{
        margin-left: 2%;
        width: 100%;
    }
    .div-contact-form-email{
        margin-left: 2%;
        width: 100%;
    }
    .div-contact-form-button{
        width: 100%;
    }

    .contact-text{
        width: 60%;  margin-left: 35%;
        margin-top: -40%;
    }
    .contact-text>h1{
     font-size: 28px;
    }
    .contact-text>h2{
     font-size: 18px;
    }
    .contact-text>h3{
     font-size: 13px;
    }
    .contact-img{
     margin-top: 12%;
     margin-left: 5%;
     width: 40%;
    }
}


@media screen and (max-width: 760px) {
  .text-mini-kafle>h1{
    font-size: 22px;
  }
    .contact-mini-tel{
        width: 80%;
        margin-left: 10%;
    }
    .contact-mini-tel>h2{
font-size: 20px;
    }
    .contact-mini-mail{
        width: 80%;
        margin-left: 10%;
    }
    .contact-mini-mail>h2{
        font-size: 20px;
    }
    .contact-form{
        width: 80%;
        margin-left: 10%;
    }
    .div-contact-form-name{width: 100%;
    }
    #input-field{
    width: 180%;
    }
    .textarea-msg{
        width: 180%;
    }
    .div-contact-form-phone{
        margin-left: 2%;
        width: 100%;
    }
    .div-contact-form-email{
        margin-left: 2%;
        width: 100%;
    }
    .div-contact-form-button{
        width: 95%;
    }
    .contact-text{
        width: 60%;  margin-left: 35%;
        margin-top: -40%;
    }
    .contact-text>h1{
     font-size: 25px;
    }
    .contact-text>h2{
     font-size: 15px;
    }
    .contact-text>h3{
     font-size: 12px;
    }
    .contact-img{
     margin-top: 20%;
    }
    .contact-container{
        overflow: hidden;
      max-width: 115vh;
    }
    .contact-container> img{
        width: 115vh;
      }
}

@media screen and (max-width: 440px) {
    .contact-text{  margin-left: 35%;
        margin-top: -35%;
    }
    .contact-text>h1{
     font-size: 25px;
    }
    .contact-text>h2{
     font-size: 15px;
    }
    .contact-text>h3{
     font-size: 13px;
     margin-left: -50%;
    }
    .contact-img{
        margin-top: 25%;
    }
}