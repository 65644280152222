.about-container{
  
   padding:2%;
   border: 5px solid chartreuse; margin:3%;
   margin-left: 8%;
}
.about-kim{
    display:inline-block;
    width: 75%;
    vertical-align: top;
}
.about-kim>h2{
    margin-top: 5%;
}
.about-kim-text{
    width: 25%;
    display:inline-block;
}
.about-co-container{
margin-top: 1%;
}
.about-co{
    display:inline-block;
}
.about-co>h2{
    margin-top:3%;
}
.check{
    width: 3%;
}
@media screen and (max-width: 960px) {
    .about-container{
        margin-left: 4%;
    }
    .about-kim{
        width: 100%;
    }
    .about-kim-text{
        margin-top: 2%;
       text-align: center;
        width: 100%;
    }
    .check{
        width: 5%;
    }
}


@media screen and (max-width: 760px) {
    .about-container{
        margin-left: 1%;
    }
    .about-kim{
        width: 100%;
    }
    .about-kim-text{
        margin-top: 2%;
       text-align: center;
        width: 100%;
    }
    .check{
        width: 5%;
    }
}

